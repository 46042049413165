<template>
  <div class="memberShip" v-loading="loading">
    <div class="shipContent content_area" v-if="initiation_status === 4">
      <router-view :data_status="data_status"/>
    </div>
    <div class="initiation-box" v-else>
      <div v-if="initiation_status === 6 || initiation_status === 7" class="hint-txt">
        <i class="el-icon-success hint-txt-success"></i>
        <div class="hint-title">{{initiation_status === 6 ? "资料审核通过" : "入会成功"}}</div>
      </div>
      <initiation
        @submitForm="submitForm"
        :form_data="form_data"
        v-if="initiation_status === 1"
      />
      <div v-if="initiation_status === 2" class="hint-txt">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/formCheck.png" alt="">
        <div class="hint-title">审核中</div>
        <div class="hint-value">入会申请已提交，请耐心等待协会审核!</div>
      </div>
      <div v-if="initiation_status === 3" class="hint-txt">
        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/formErr.png" alt="">
        <div class="hint-title">审核失败</div>
        <div class="hint-value">
          <div>入会申请未通过协会审核</div>
          <div>如有异议请联系021-65374744</div>
          <div>邮箱service@scba.com.cn</div>
        </div>
      </div>
      <!-- <div v-if="initiation_status === 5" class="hint-txt">附件审核中</div> -->
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员操作入会</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import initiation from "~scb/components/form/initiation";
import fileUpload from "@/baseComponents/fileUpload";
export default {
  metaInfo: {
    title: "申请入会",
  },
  components: {
    initiation,
    fileUpload,
  },
  data() {
    return {
      initiation_status: 0, //0---初始状态；1---填表；2---表单审核；3---表单审核失败；4---上传附件；5---附件审核；6---审核通过；7---会员
      loading: false,
      dialogVisible: false,
      data_status: {},
      form_data: {},//表格数据
    };
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    // 检测状态
    getStatus(){
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return;
      }
      this.loading = true;
      this.$store.dispatch('baseConsole/getScbaApplyFormQuery', {
        company_id: this.USER_INFO.company_id,
        source: this.PJSource,
        query_type: "2",
        company_name: this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en, 
      }).then(res=>{
        // 3  2
        if (res.success) {
          this.form_data = res.data.form_status == 4 ? res.data : {};
          if (res.data.form_status == 0) {
            this.initiation_status = 2;
          }else if (res.data.form_status == 1) {
            this.data_status = {
              data_status: res.data.data_status,
              form_id: res.data.id,
              attachment_data:JSON.parse(res.data.attachment_data)
            };
            if (res.data.data_status == 2) {
              if (this.USER_INFO.aila_no) {
                this.initiation_status = 7;
              } else {
                this.initiation_status = 6;
              }
            }else{
              this.initiation_status = 4;
            }
          }else if (res.data.form_status == 2) {
            this.initiation_status = 3;
          }else if (res.data.form_status == 3) {
            if (!this.USER_INFO.aila_no) {
              this.$store.dispatch("baseStore/baseSign_getUserInfo", { user_id: this.USER_INFO.id }).then(user_info=>{
                this.getUserStatus();
              }).catch(err=>{
                this.getUserStatus();
              })
            }else {
              this.getUserStatus();
            }
          }else {
            this.initiation_status = 1;
          }
        }
        this.loading = false;
      }).catch(err=>{
        this.loading = false;
      })
    },
    // 获取状态
    getUserStatus(){
      if (this.USER_INFO.aila_no) {
        this.initiation_status = 7;
      } else {
        this.initiation_status = 6;
      }
    },
    // 表单提交
    submitForm(value) {
      if(!this.USER_INFO.is_admin){
        this.dialogVisible = true;
        return
      }
      this.loading = true;
      let params = Object.assign({}, value);
      params.characteristics_business = params.characteristics_business.toString();
      params.company_advantage = params.company_advantage.toString();
      params.expect_service = params.expect_service.toString();
      params.declaration_registration_date = params.declaration_registration_date ? this.$moment(params.declaration_registration_date).unix() : '';
      params.porganization_time = params.porganization_time ? this.$moment(params.porganization_time).unix() : '';
      params._NOCLEAR = true;
      this.$store.dispatch("API_position/quickToMeeting", params).then(res=>{ 
        if (res.success) {
          this.$message.success("申请提交成功");
          this.initiation_status = 2;
          this.loading = false;
        }else {
          this.$message.error("提交申请失败！请稍后再试");
          this.loading = false;
        }
      }).catch(err=>{
        this.$message.error("提交申请失败！请稍后再试");
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.memberShip {
  min-height:calc(100vh - 449px);
  background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/initiation_bg.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 100px 0 78px;
  .initiation-box {
    width: 1200px;
    min-height: 30vh;
    background: #ffffff;
    margin: 0 auto;
    padding: 40px;
    box-sizing: border-box;
    .hint-txt{
      color: #333333;
      text-align: center;
      margin-left: 12px;
      img{
        width: 213px;
        height: 177px;
      }
      .hint-title{
        font-size: 20px;
        font-weight: bold;
        margin-top: 30px;
      }
      .hint-value{
        font-size: 14px;
        margin-top: 16px;
        line-height: 24px;
      }
      .hint-txt-success{
        color: #e3954f;
        font-size: 120px;
      }
    }
  }
  .shipContent {
    background: #ffffff;
    padding: 47px 0 74px;
  }
}
</style>
